.input {
  width: 32px;
  height: 34px;
  padding: 0;
  margin: 0 6px 0 0;
  font-family: inherit;
  font-size: rem-convert(14px);
  line-height: 1;
  text-align: center;
  appearance: textfield;
  border-radius: 4px;
  transition: border $durationDefault;

  /* THEMES */
  &.light {
    color: hsl($colorGrey900);
    border: 1px solid hsl($colorGrey200);

    &.primary {
      background: hsl($colorGrey50);
    }

    &.secondary {
      background: hsl($colorWhite);
    }

    &:focus {
      border-color: hsl($colorBrandBlue500);
    }
  }

  &.dark {
    color: hsl($colorGrey50);
    border: 1px solid hsl($colorGrey850);

    &.primary {
      background: hsl($colorGrey950);
    }

    &.secondary {
      background: hsl($colorGrey900);
    }

    &:focus {
      border-color: hsl($colorInfo500);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:nth-child(3) {
    margin-right: 16px;
  }

  &:last-child {
    margin: 0;
  }
}

@media (min-width: $sm) {
  .input {
    width: 36px;
    height: 38px;
    margin-right: 8px;
    font-size: rem-convert(15px);

    &:nth-child(3) {
      margin-right: 28px;
    }
  }
}
