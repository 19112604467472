.wrapper {
  display: flex;
  min-height: 100vh;
  padding: 40px 0 10%;
}

.content {
  min-height: 400px;
  margin: auto;
}

.logoWrapper {
  display: block;
  text-align: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  &.ready {
    opacity: 1;
  }
}

.container {
  height: 100%;
}
