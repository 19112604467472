.modal {
  margin: 60px auto auto;
  animation: slideIn 100ms ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}
